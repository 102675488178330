export const isDrupalSessionActive = () => {
  if (typeof document === 'object') {
    const rawCookies = `; ${document.cookie}`;
    return (
      rawCookies.includes(' DRUPAL_AUTHENTICATED_USER=') &&
      !rawCookies.includes(' DRUPAL_AUTHENTICATED_USER=front-user')
    );
  }
  return false;
};
