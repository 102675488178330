import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { IconPencil } from '../../../Icon/IconPencil';
import { isDrupalSessionActive } from '../../../Header/components/helpers/isDrupalSessionActive';

const styles = {
  main: css`
    display: inline-block;
    position: absolute;
  `
};

const getLink = (entityType, entityId) => {
  let link;
  switch (entityType) {
    case 'menu':
      link = `/admin/structure/menu/manage/${entityId}`;
      break;
    case 'taxonomy_term':
      link = `/admin/structure/taxonomy/manage/${entityId}/overview`;
      break;
    default:
      throw new Error(`Unknown entityType: "${entityType}"`);
  }
  return link;
};

export const DrupalEdit = ({ entityType, entityId, color }) => {
  return isDrupalSessionActive() ? (
    <a
      href={`/user/login?destination=${getLink(entityType, entityId)}`}
      title={`Edit this ${entityType}`}
      aria-label={`Edit this ${entityType}`}
      rel="noopener noreferrer"
      css={styles.main}
    >
      <IconPencil color={color} size={20} />
    </a>
  ) : null;
};

DrupalEdit.defaultProps = {
  color: 'black'
};

DrupalEdit.propTypes = {
  entityType: PropTypes.oneOf(['menu', 'taxonomy_term']).isRequired,
  entityId: PropTypes.string.isRequired,
  color: PropTypes.string
};
